import { Observable, Subscription, merge, of, timer } from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    exhaustMap,
    map,
    startWith,
    switchMap,
    timeout
} from 'rxjs/operators';

import { Inject, Injectable, InjectionToken } from '@angular/core';
import { REQUEST_HEARTBEAT } from '@mhp-immersive-exp/contracts/src/heartbeat/heartbeat-request.interface';
import { HeartbeatRequestPayload } from '@mhp-immersive-exp/contracts/src/websocket/socket-request.interface';
import { IllegalStateError, MemoizeObservable } from '@mhp/common';

import {
    ApplicationStateService,
    UiGlobalApplicationState,
    setEngineConnectionState,
    setHubConnectionState
} from '../../application-state';
import { SocketIOService } from '../../communication';
import {
    selectEngineConnectionState,
    selectHubConnectionState
} from '../state';

export interface EngineConnectionStateServiceConfig {
    /**
     * Interval in which to ping the engine.
     * Set to 0 to disable engine-ping. Engine connection status will be undefined then.
     */
    applicationHeartbeatInterval: number;

    /**
     * The amount of unanswered heartbeat-requests until engine is assumed to be unavailable.
     * Defaults to 3
     */
    applicationHeartbeatMaxFailures?: number;

    /**
     * The identifier for the client-application which is pinging the engine.
     * Possibly used by the engine for additional functionality (e.g. show qr-code in case tablet-ui is not connected).
     */
    applicationHeartbeatClient?: string;
}

export const ENGINE_CONNECTION_STATE_SERVICE_CONFIG_TOKEN =
    new InjectionToken<EngineConnectionStateServiceConfig>(
        'ConnectionStateServiceConfig'
    );

const DEFAULT_HEARTBEAT_MAX_FAILURE_RATE = 3;

@Injectable()
export class EngineConnectionStateService {
    private engineConnectionStateSubscription?: Subscription;

    private hubConnectionStateSubscription?: Subscription;

    constructor(
        private socketIoService: SocketIOService,
        private applicationStateService: ApplicationStateService<
            UiGlobalApplicationState<any>
        >,
        @Inject(ENGINE_CONNECTION_STATE_SERVICE_CONFIG_TOKEN)
        private connectionStateServiceConfig: EngineConnectionStateServiceConfig
    ) {}

    /**
     * Start connection checks
     */
    startConnectionChecks() {
        if (
            this.engineConnectionStateSubscription &&
            this.hubConnectionStateSubscription
        ) {
            throw new IllegalStateError('Connection check already active');
        }

        this.engineConnectionStateSubscription =
            this.initEngineConnectionState();
        this.hubConnectionStateSubscription = this.initHubConnectionState();
    }

    /**
     * Stop connection checks and set connection state of hub to false and engine to undefined.
     */
    stopConnectionCheck() {
        [
            this.engineConnectionStateSubscription,
            this.hubConnectionStateSubscription
        ].forEach((subscription) => subscription?.unsubscribe());

        this.engineConnectionStateSubscription = undefined;
        this.hubConnectionStateSubscription = undefined;

        this.setEngineConnectionState(undefined);
        this.setHubConnectionState(false);
    }

    /**
     * Returns the connection state of the engine.
     */
    @MemoizeObservable()
    getEngineConnectionState$(): Observable<boolean | undefined> {
        return this.applicationStateService
            .getLocalSharedState()
            .pipe(selectEngineConnectionState);
    }

    /**
     * Returns the connection state of the hub.
     */
    @MemoizeObservable()
    getHubConnectionState$(): Observable<boolean | undefined> {
        return this.applicationStateService
            .getLocalSharedState()
            .pipe(selectHubConnectionState);
    }

    private initEngineConnectionState() {
        return merge(
            this.socketIoService
                .subscribe('engineconnected')
                .pipe(map(() => true)),
            this.socketIoService.getConnectionState$().pipe(
                switchMap((hubConnectionState) => {
                    if (!hubConnectionState) {
                        return of(undefined);
                    }
                    return this.initEnginePing();
                })
            )
        )
            .pipe(distinctUntilChanged())
            .subscribe((engineConnectionState: boolean | undefined) => {
                this.setEngineConnectionState(engineConnectionState);
            });
    }

    private initHubConnectionState() {
        return this.socketIoService
            .getConnectionState$()
            .subscribe((hubConnectionState) => {
                this.setHubConnectionState(hubConnectionState);
            });
    }

    private initEnginePing(): Observable<boolean | undefined> {
        let heartbeatFailureCount = 0;

        if (
            this.connectionStateServiceConfig.applicationHeartbeatInterval <= 0
        ) {
            return of(undefined);
        }

        return timer(
            0,
            this.connectionStateServiceConfig.applicationHeartbeatInterval
        ).pipe(
            exhaustMap(() =>
                this.socketIoService
                    .request<HeartbeatRequestPayload, Record<string, never>>(
                        REQUEST_HEARTBEAT,
                        {
                            heartbeatInterval:
                                this.connectionStateServiceConfig
                                    .applicationHeartbeatInterval,
                            client:
                                this.connectionStateServiceConfig
                                    .applicationHeartbeatClient || 'unknown'
                        }
                    )
                    .pipe(
                        timeout(
                            this.connectionStateServiceConfig
                                .applicationHeartbeatInterval
                        ),
                        map(() => {
                            heartbeatFailureCount = 0;
                            return true;
                        }),
                        catchError(() => {
                            heartbeatFailureCount += 1;

                            if (
                                heartbeatFailureCount <
                                (this.connectionStateServiceConfig
                                    .applicationHeartbeatMaxFailures ??
                                    DEFAULT_HEARTBEAT_MAX_FAILURE_RATE)
                            ) {
                                return of(true);
                            }
                            return of(false);
                        }),
                        startWith(false)
                    )
            )
        );
    }

    private setEngineConnectionState(state: boolean | undefined) {
        this.applicationStateService.dispatch(
            setEngineConnectionState({
                connectionState: state
            })
        );
    }

    private setHubConnectionState(state: boolean) {
        this.applicationStateService.dispatch(
            setHubConnectionState({
                connectionState: state
            })
        );
    }
}
