import { isBoolean } from 'lodash-es';
import { Observable, firstValueFrom, iif } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Inject, Injectable } from '@angular/core';
import { GetCamerasResponsePayload } from '@mhp-immersive-exp/contracts/src/camera/camera.interface';
import { AMLCameraMeta } from '@mhp/aml-shared/product-data/aml-camera-meta.interface';
import { AMLEnvironmentMeta } from '@mhp/aml-shared/product-data/aml-environment-meta.interface';
import { AMLProductMeta } from '@mhp/aml-shared/product-data/aml-product-meta.interface';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import {
    ApplicationStateService,
    PRODUCT_DATA_STRATEGY_PROVIDER_TOKEN,
    ProductDataService,
    StrategyProvider,
    withCurrentStrategy$,
    withStrategy$
} from '@mhp/ui-shared-services';

import { ConfigurationSessionInfoService } from '../configuration/session-info/configuration-session-info.service';
import {
    AmlProductDataStrategy,
    ProductInfo
} from './aml-product-data-strategy.interface';

/**
 * This is an extension of the basic ProductDataService
 */
@Injectable({
    providedIn: 'root'
})
export class AmlProductDataService extends ProductDataService<
    AMLCameraMeta,
    AMLEnvironmentMeta,
    AmlProductDataStrategy
> {
    constructor(
        applicationStateService: ApplicationStateService,
        @Inject(PRODUCT_DATA_STRATEGY_PROVIDER_TOKEN)
        strategyProvider: StrategyProvider<AmlProductDataStrategy>,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService
    ) {
        super(applicationStateService, strategyProvider);
    }

    /**
     * Returns a list of available models for the currently active region.
     * Models are filtered based on the boolean meta flag: configurator.
     */
    @MemoizeObservable()
    getAvailableModels$(): Observable<string[]> {
        return withStrategy$(this.strategyProvider).pipe(
            switchMap((strategy) =>
                strategy.getAvailableProductInfos$().pipe(
                    map((productsData) => {
                        const filteredModelCodes =
                            this.keepConfiguratorRelevantEntriesOnly(
                                productsData
                            ).map((productData) => productData.modelCode);

                        return [...new Set(filteredModelCodes)];
                    })
                )
            ),
            lazyShareReplay()
        );
    }

    /**
     * Emit the currently valid products for a given model.
     * @param modelCode
     */
    getAvailableProductsForModel$(
        modelCode: string
    ): Observable<ProductInfo[]> {
        return withStrategy$(this.strategyProvider).pipe(
            switchMap((strategy) =>
                strategy
                    .getAvailableProductsForModel$(modelCode)
                    .pipe(
                        map((productInfoData) =>
                            this.keepConfiguratorRelevantEntriesOnly(
                                productInfoData
                            )
                        )
                    )
            )
        );
    }

    /**
     * Emits the ProductInfo for a given productId.
     * @param productId The productId for which to fetch a matching ProductInfo.
     * @param country Optionally the country to use. If not provided, the currently active country will be used.
     */
    getProductInfo$(
        productId: string,
        country?: string
    ): Observable<ProductInfo | undefined> {
        return withStrategy$(this.strategyProvider).pipe(
            switchMap((strategy) =>
                strategy.getProductInfo$(productId, country)
            )
        );
    }

    /**
     * Emits the product-meta for the given productId.
     * @param productId Optional - In case productId is provided, use it. Otherwise fall back to the currently active one.
     * @param country
     */
    getProductMeta$(
        productId?: string,
        country?: string
    ): Observable<AMLProductMeta | undefined> {
        return iif(
            () => !!productId,
            this.getProductInfo$(productId as string, country),
            this.getActiveProductInfo$()
        ).pipe(map((info) => info?.meta));
    }

    /**
     * Emits the ProductInfo for the currently active productId.
     */
    getActiveProductInfo$(): Observable<ProductInfo | undefined> {
        return withStrategy$(this.strategyProvider).pipe(
            switchMap((strategy) =>
                strategy.getActiveProductInfo$(
                    this.configurationSessionInfoService.getActiveProductId$()
                )
            )
        );
    }

    /**
     * Get a list of all available cameras for the given product.
     */
    getAvailableCamerasForProduct$(
        productId: string,
        skipEnvironmentFilter?: boolean
    ): Observable<GetCamerasResponsePayload<AMLCameraMeta> | undefined> {
        return withCurrentStrategy$(this.strategyProvider).pipe(
            switchMap((strategy) =>
                strategy.getAvailableCameras$(productId, {
                    skipEnvironmentFilter
                })
            ),
            lazyShareReplay()
        );
    }

    /**
     * Resolve the follow-up model-year code for a given productId.
     * @param productId The productId to be checked
     * @return Resolves either to the same productId as given as input (-> productId is most current), to a different productId
     * (-> given productId is not the most recent one, use the returned on to continue) or to undefined, which means the given
     * productId is no longer known.
     */
    async resolveFollowUpModelYearCode(productId: string): Promise<string> {
        return firstValueFrom(
            withCurrentStrategy$(this.strategyProvider).pipe(
                switchMap((strategy) =>
                    strategy.resolveFollowUpModelYearCode(productId)
                )
            )
        );
    }

    private keepConfiguratorRelevantEntriesOnly<
        T extends { meta?: AMLProductMeta }
    >(entries: T[]): T[] {
        return entries.filter(
            (entry) =>
                !isBoolean(entry?.meta?.configurator) ||
                entry?.meta?.configurator === true
        );
    }
}
