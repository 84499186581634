import { EventEmitter } from '@angular/core';

export enum DialogButtonType {
    PRIMARY,
    SECONDARY,
    LINK
}

export enum DialogButtonPlacement {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
}

export interface Button {
    id: string;
    type: DialogButtonType;
    disabled?: boolean;
    placement?: DialogButtonPlacement;
    label: string;
}

export interface GenericTextModal {
    title: string;
    text: string;
    useWrappingParagraph: boolean;
    renderFullWidthButton: boolean;
    buttonsLeft?: Button[];
    buttonsRight?: Button[];
    buttonClick: EventEmitter<string>;
}
