import { isUndefined } from 'lodash-es';
import { Observable, combineLatest, firstValueFrom, of } from 'rxjs';
import {
    distinctUntilChanged,
    first,
    map,
    skip,
    switchMap,
    take,
    tap
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
    IllegalStateError,
    MemoizeObservable,
    lazyShareReplay
} from '@mhp/common';
import { ApplicationStateService } from '@mhp/ui-shared-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AmlProductDataService } from '../../product-data/aml-product-data-service';
import { LocalApplicationState } from '../../state';
import { ExtendedUiOptionCode } from '../configuration-model/configuration-interfaces';
import { isExtendedUiOptionCode } from '../services/configuration-helper';
import { ConfigurationNodeLookupService } from '../services/configuration-node-lookup.service';
import { ProductConfigurationSessionService } from '../services/product-configuration-session.service';
import { ConfigurationSessionInfoService } from '../session-info/configuration-session-info.service';
import { setUserToggledRoof } from '../state';

export const CODE_LIGHTS_OFF = 'MHPCODELGTDRL';
export const CODE_LIGHTS_ON = 'MHPCODELGTLB';

export const CODE_ROOF_CLOSED = 'MHPCODERoofClosed';
export const CODE_ROOF_OPEN = 'MHPCODERoofOpen';

const CODE_ROOF_BLIND_CLOSED = 'MHPCODESunroofClosed';
const CODE_ROOF_BLIND_OPEN = 'MHPCODESunroofOpen';

/**
 * Abstracts toggling car features and getting their current state.
 */
@UntilDestroy()
@Injectable()
export class CarFeatureControlService {
    constructor(
        private readonly productConfigurationSessionService: ProductConfigurationSessionService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly configurationNodeLookupService: ConfigurationNodeLookupService,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly productDataService: AmlProductDataService
    ) {
        this.initResetUserToggledRoofStateOnProductIdChange();
    }

    /**
     * Gets the current state of the lights as ON / OFF.
     */
    @MemoizeObservable()
    getLightsState$(): Observable<'ON' | 'OFF' | undefined> {
        return this.productConfigurationSessionService
            .getOptionGroupsIncludingNonVisible$()
            .pipe(
                map((optionGroups): 'ON' | 'OFF' | undefined => {
                    if (!optionGroups) {
                        return undefined;
                    }

                    const activeLightsNode =
                        this.configurationNodeLookupService.findNode(
                            (node): node is ExtendedUiOptionCode =>
                                isExtendedUiOptionCode(node) &&
                                node.selected &&
                                (node.code === CODE_LIGHTS_OFF ||
                                    node.code === CODE_LIGHTS_ON),
                            optionGroups
                        )?.node;

                    if (!activeLightsNode) {
                        return undefined;
                    }

                    return activeLightsNode.code === CODE_LIGHTS_OFF
                        ? 'OFF'
                        : 'ON';
                }),
                distinctUntilChanged(),
                lazyShareReplay()
            );
    }

    /**
     * Toggle the status of the products lights.
     */
    setLightsState$(targetState: 'ON' | 'OFF'): Observable<void> {
        return this.productConfigurationSessionService
            .getOptionGroupsIncludingNonVisible$()
            .pipe(
                take(1),
                switchMap((optionGroups) => {
                    if (!optionGroups) {
                        return of(undefined);
                    }

                    const codeToSelect =
                        targetState === 'ON' ? CODE_LIGHTS_ON : CODE_LIGHTS_OFF;
                    const lightNodeToSelect =
                        this.configurationNodeLookupService.findNode(
                            (node): node is ExtendedUiOptionCode =>
                                isExtendedUiOptionCode(node) &&
                                node.code === codeToSelect &&
                                !node.selected,
                            optionGroups
                        )?.node;

                    if (lightNodeToSelect) {
                        return this.productConfigurationSessionService.updateNodeSelection(
                            lightNodeToSelect.id
                        );
                    }

                    return of(undefined);
                })
            );
    }

    /**
     * Gets the current state of the roof as OPEN / CLOSED or undefined in case it is unsupported.
     */
    @MemoizeObservable()
    getRoofState$(): Observable<'OPEN' | 'CLOSED' | undefined> {
        return this.getOptionStateOpenClose$(
            CODE_ROOF_OPEN,
            CODE_ROOF_CLOSED,
            async (productId) =>
                !!(await this.getProductInfoMetaForProduct$(productId))
                    ?.isConvertible
        );
    }

    /**
     * Toggle the status of the products roof.
     */
    setRoofState$(targetState: 'OPEN' | 'CLOSED'): Observable<void> {
        return this.setOptionState$(
            CODE_ROOF_OPEN,
            CODE_ROOF_CLOSED,
            targetState === 'OPEN',
            async (productId) =>
                !!(await this.getProductInfoMetaForProduct$(productId))
                    ?.isConvertible
        );
    }

    /**
     * Toggle the status of the products roof.
     */
    toggleRoofState$(): Observable<void> {
        return this.toggleOptionState$(
            CODE_ROOF_OPEN,
            CODE_ROOF_CLOSED,
            async (productId) =>
                !!(await this.getProductInfoMetaForProduct$(productId))
                    ?.isConvertible
        ).pipe(
            // set user-toggled-roof-state
            tap(() =>
                this.applicationStateService.dispatch(
                    setUserToggledRoof({
                        userToggledRoof: true
                    })
                )
            )
        );
    }

    /**
     * Gets the current state of the roof-blind as OPEN / CLOSED or undefined in case it is unsupported.
     */
    @MemoizeObservable()
    getRoofBlindState$(): Observable<'OPEN' | 'CLOSED' | undefined> {
        return this.getOptionStateOpenClose$(
            CODE_ROOF_BLIND_OPEN,
            CODE_ROOF_BLIND_CLOSED,
            async (productId) =>
                !!(await this.getProductInfoMetaForProduct$(productId))
                    ?.hasRoofBlind
        );
    }

    /**
     * Toggle the status of the products roof.
     */
    setRoofBlindState$(targetState: 'OPEN' | 'CLOSED'): Observable<void> {
        return this.setOptionState$(
            CODE_ROOF_BLIND_OPEN,
            CODE_ROOF_BLIND_CLOSED,
            targetState === 'OPEN',
            async (productId) =>
                !!(await this.getProductInfoMetaForProduct$(productId))
                    ?.hasRoofBlind
        );
    }

    /**
     * Toggle the status of the products roof-blind.
     */
    toggleRoofBlindState$(): Observable<void> {
        return this.toggleOptionState$(
            CODE_ROOF_BLIND_OPEN,
            CODE_ROOF_BLIND_CLOSED,
            async (productId) =>
                !!(await this.getProductInfoMetaForProduct$(productId))
                    ?.hasRoofBlind
        );
    }

    private async getProductInfoMetaForProduct$(productId: string) {
        return firstValueFrom(
            this.productDataService.getProductMeta$(productId)
        );
    }

    private getOptionStateOpenClose$(
        optionCodeOn: string,
        optionCodeOff: string,
        availableForProductCallback: (productId: string) => Promise<boolean>
    ): Observable<'OPEN' | 'CLOSED' | undefined> {
        return this.getOptionState$(
            optionCodeOn,
            optionCodeOff,
            availableForProductCallback
        ).pipe(
            map((state) => {
                if (isUndefined(state)) {
                    return undefined;
                }
                return state ? 'OPEN' : 'CLOSED';
            })
        );
    }

    private getOptionState$(
        optionCodeOn: string,
        optionCodeOff: string,
        availableForProductCallback: (productId: string) => Promise<boolean>
    ): Observable<boolean | undefined> {
        return combineLatest([
            this.productConfigurationSessionService.getOptionGroupsIncludingNonVisible$(),
            this.configurationSessionInfoService.getActiveProductId$()
        ]).pipe(
            switchMap(
                async ([optionGroups, productId]): Promise<
                    boolean | undefined
                > => {
                    if (!optionGroups || !productId) {
                        return undefined;
                    }

                    if (!(await availableForProductCallback(productId))) {
                        return undefined;
                    }

                    const activeNode =
                        this.configurationNodeLookupService.findNode(
                            (node): node is ExtendedUiOptionCode =>
                                isExtendedUiOptionCode(node) &&
                                node.selected &&
                                (node.code === optionCodeOff ||
                                    node.code === optionCodeOn),
                            optionGroups
                        )?.node;

                    if (!activeNode) {
                        return undefined;
                    }

                    return activeNode.code !== optionCodeOff;
                }
            ),
            distinctUntilChanged(),
            lazyShareReplay()
        );
    }

    setOptionState$(
        optionCodeOn: string,
        optionCodeOff: string,
        targetState: boolean,
        availableForProductCallback?: (productId: string) => Promise<boolean>
    ): Observable<void> {
        return combineLatest([
            this.productConfigurationSessionService.getOptionGroupsIncludingNonVisible$(),
            this.configurationSessionInfoService.getActiveProductId$()
        ]).pipe(
            first(),
            switchMap(async ([optionGroups, activeProductId]) => {
                if (!optionGroups || !activeProductId) {
                    return undefined;
                }

                if (
                    availableForProductCallback &&
                    !(await availableForProductCallback(activeProductId))
                ) {
                    return undefined;
                }

                const codeToSelect = targetState ? optionCodeOn : optionCodeOff;
                const nodeToSelect =
                    this.configurationNodeLookupService.findNode(
                        (node): node is ExtendedUiOptionCode =>
                            isExtendedUiOptionCode(node) &&
                            node.code === codeToSelect &&
                            !node.selected,
                        optionGroups
                    )?.node;

                if (nodeToSelect) {
                    this.productConfigurationSessionService.updateNodeSelection(
                        nodeToSelect.id
                    );
                }

                return undefined;
            })
        );
    }

    private toggleOptionState$(
        optionCodeOn: string,
        optionCodeOff: string,
        availableForProductCallback: (productId: string) => Promise<boolean>
    ) {
        return this.getOptionState$(
            optionCodeOn,
            optionCodeOff,
            availableForProductCallback
        ).pipe(
            first(),
            switchMap((optionState) => {
                if (optionState === undefined) {
                    throw new IllegalStateError(
                        `Cannot toggle state of options ${optionCodeOn} / ${optionCodeOff} as it seems to be unsupported`
                    );
                }

                return this.setOptionState$(
                    optionCodeOn,
                    optionCodeOff,
                    !optionState,
                    availableForProductCallback
                );
            })
        );
    }

    /**
     * Whenever the product changes, reset the userToggledRoof state.
     * @private
     */
    private initResetUserToggledRoofStateOnProductIdChange() {
        this.configurationSessionInfoService
            .getActiveProductId$()
            .pipe(skip(1), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(() => {
                // set user-toggled-roof-state
                this.applicationStateService.dispatch(
                    setUserToggledRoof({
                        userToggledRoof: false
                    })
                );
            });
    }
}
